import clsx from 'clsx';
import Column from 'components/column';
import ProjectCard from 'components/project-card';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';
import Body from 'sections/body';
import GallerySection from 'sections/gallery-section';
import Hero from 'sections/hero';
import ListSection from 'sections/list-section';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import * as styles from './project.module.scss';
import LangSlug from 'components/lang-slug';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Spacing from 'sections/spacing';

type ProjectProps = {
  data: GatsbyTypes.ProjectPageQueryQuery;
};

const ProjectMeta: React.FC<Partial<GatsbyTypes.DatoCmsProject>> = (project) => {
  const { t } = useTranslation();
  return (
    <div className={styles.topSectionMeta}>
      {project.title && (
        <h4>
          <span>{t('Projekt')}:</span> {project.title}
        </h4>
      )}
      {project.projectCategory && (
        <h4>
          <span>{t('Uppdragstyp')}:</span>{' '}
          {project.projectCategory.map((pc) => pc?.title).join(' / ')}
        </h4>
      )}
      {project.client && (
        <h4>
          <span>{t('Kund')}:</span> {project.client}
        </h4>
      )}
      {project.year && (
        <h4>
          <span>{t('År')}:</span> {project.year}
        </h4>
      )}
    </div>
  );
};

const Project: React.FC<ProjectProps> = ({ data }) => {
  if (!data.datoCmsProject) return null;

  const {
    datoCmsProject: project,
    allDatoCmsProject: { latestProjects },
  } = data;

  return (
    <>
      <LangSlug langList={project.langSlugs as GatsbyTypes.LangSlugFragment[]} model={'project'} />
      <HelmetDatoCms seo={project.seoMetaTags} />
      <Hero image={project.featuredImage} />
      <Section className={styles.topSection}>
        <Column xl={6} lg={6} className={styles.topSectionColLeft}>
          {project.topLeftColumn && (
            <Column md={7} sm={6}>
              <div dangerouslySetInnerHTML={{ __html: project.topLeftColumn }} />
            </Column>
          )}
          <Column md={{ width: 4, offset: 1 }} sm={{ width: 5, offset: 1 }}>
            <ProjectMeta {...(project as GatsbyTypes.DatoCmsProject)} />
          </Column>
        </Column>
        {project.topRightColumn && (
          <Column
            xl={{ width: 5, offset: 1 }}
            lg={{ width: 5, offset: 1 }}
            md={9}
            className={styles.topSectionColRight}
          >
            <div dangerouslySetInnerHTML={{ __html: project.topRightColumn }} />
          </Column>
        )}
      </Section>
      {project.gallerySlides && project.gallerySlides.length > 0 && (
        <GallerySection
          slides={project.gallerySlides}
          backgroundColor={'light'}
          topBackgroundColor={'blue'}
          topBackgroundOffset={25}
        />
      )}
      {(!project.gallerySlides || project.gallerySlides.length <= 0) &&
        project.listTitle &&
        project.listItems &&
        project.listItems.length > 0 && <Spacing />}
      {project.listTitle && project.listItems && project.listItems.length > 0 && (
        <ListSection
          insetColumn
          title={project.listTitle}
          listItems={project.listItems}
          backgroundColorContainer={'white'}
          backgroundColor={'light'}
        />
      )}
      {project.bodyText && <Body data={project.bodyText} />}
      {(project.bottomLeftColumn || project.bottomRightColumn) && (
        <Section backgroundColor={'concrete'} className={styles.bottomSection}>
          {project.bottomLeftColumn && (
            <Column
              className={clsx(styles.bottomSectionColumn, styles.bottomSectionColumnLeft)}
              xl={4}
              lg={5}
              md={5}
              sm={5}
            >
              <h1>{project.bottomLeftColumn}</h1>
            </Column>
          )}
          {project.bottomRightColumn && (
            <Column
              className={clsx(styles.bottomSectionColumn, styles.bottomSectionColumnRight)}
              dangerouslySetInnerHTML={{ __html: project.bottomRightColumn }}
              xl={{ width: 7, offset: 1 }}
              lg={7}
              md={7}
              sm={7}
            />
          )}
        </Section>
      )}
      <Section className={styles.projectSection} backgroundColor={'black'}>
        {latestProjects.map((p, _, list) => (
          <ProjectCard
            key={p.id}
            width={list.length <= 1 ? 'full' : 'half'}
            className={styles.projectCard}
            imageClassName={styles.projectCardImage}
            contentClassName={styles.projectCardContent}
            {...(p as GatsbyTypes.DatoCmsProject)}
          />
        ))}
      </Section>
    </>
  );
};

export default Project;

export const query = graphql`
  query ProjectPageQuery($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allDatoCmsProject(filter: { id: { ne: $id } }, limit: 2, sort: { fields: year, order: DESC }) {
      latestProjects: nodes {
        id
        title
        slug
        client
        year
        projectCategory {
          title
        }
        featuredImage {
          ...Image
        }
        model {
          apiKey
        }
      }
    }
    datoCmsProject(id: { eq: $id }) {
      id
      title
      langSlugs {
        ...LangSlug
      }
      featuredImage {
        ...Image
      }
      projectCategory {
        title
      }
      seoMetaTags {
        tags
      }
      client
      year
      topLeftColumn
      topRightColumn
      gallerySlides {
        ...GallerySlide
      }
      listTitle
      listItems {
        id
        text
        title
      }
      bodyText {
        ...ProjectBodyText
      }
      bottomLeftColumn
      bottomRightColumn
    }
  }
`;
