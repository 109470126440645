// extracted by mini-css-extract-plugin
export var topSection = "project-module--topSection--8UPdz";
export var topSectionColLeft = "project-module--topSectionColLeft--Vd9ho";
export var topSectionColRight = "project-module--topSectionColRight--bWglB";
export var topSectionMeta = "project-module--topSectionMeta--enEQG";
export var bottomSection = "project-module--bottomSection--WRzFP";
export var bottomSectionColumn = "project-module--bottomSectionColumn--xCYb9";
export var bottomSectionColumnLeft = "project-module--bottomSectionColumnLeft--j70vu";
export var bottomSectionColumnRight = "project-module--bottomSectionColumnRight--AO4aY";
export var projectSection = "project-module--projectSection--ERT+W";
export var projectCard = "project-module--projectCard--UIxWt";
export var projectCardImage = "project-module--projectCardImage--cP2fR";
export var projectCardContent = "project-module--projectCardContent--K0vFj";